
import { Options, Vue } from "vue-property-decorator";
import * as api from "@/api/visitor";

@Options({})
export default class extends Vue {
  private progressDetail: any = {};
  private queryVal: any = null;
  show = false;
  private previewimg = "";

  approveStatusFilter(val: any) {
    if (val === 0) {
      return "未审批";
    } else if (val === 1) {
      return "未开始";
    } else if (val === 2) {
      return "生效中";
    } else if (val === 3) {
      return "已结束";
    }
  }

  protected previewimgfun(arg: any) {
    this.previewimg = arg;
    this.show = true;
  }

  examine(val: any) {
    this.progressDetail.status = val;
    api
      .approveInfo({
        ...this.progressDetail,
      })
      .then((res:any) => {
        if (res.code === 0) {
          this.$toast.success({
            message: res.msg,
            forbidClick: true,
            onClose: () => {
              this.$router.go(-1);
            },
          });
        } else {
          this.$toast.fail(res.msg);
        }
      });
  }

  approveSexFilter(val: any) {
    if (val === 1) {
      return "未知";
    } else if (val === 2) {
      return "男";
    } else if (val === 3) {
      return "女";
    }
  }

  mounted() {
    this.queryVal = this.$route.query.val;
    this.progressDetail = JSON.parse(this.queryVal);
  }
}
